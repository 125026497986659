/* THIS FILE ALWAYS LOADED FIRST - MINIMUM PRECEDENCE */
.button,
.anchor {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  border: none;
  border-radius: 0;
  background: none;
  padding: 0;
  cursor: pointer;
  outline: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  text-align: left;
  text-decoration: none;
}

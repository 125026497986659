.h1 {
  font-family: var(--font-headers);
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: normal;
  font-variant: normal;
}

.h2 {
  font-family: var(--font-headers);
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: normal;
  font-variant: normal;
}

.h3 {
  font-family: var(--font-headers);
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: normal;
  font-variant: normal;
}

.title {
  font-family: var(--font-headers);
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2;
  letter-spacing: normal;
  font-variant: small-caps;
}

.body {
  font-family: var(--font-body);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: normal;
  font-variant: normal;
}

.fine {
  font-family: var(--font-body);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: normal;
  font-variant: normal;
}

.tight {
  line-height: 1;
}

.noWrap {
  white-space: nowrap;
}

.legibleMaxWidth {
  max-width: 80ch;
}
